import React, { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";

import styles from "./mainSplide.module.scss";
import authorMockup from "../../../assets/images/mockup/authorMockup.jpg";
import slug from "slug";
import { authorUrl, postUrl } from "../../Helpers/myUrlHelper";
import translation from "../../Helpers/translation";

function MainSplide({ data, tagClick }) {
    const splide = useRef();
    const { lang } = useParams();

    const startAutoplay = () => {
        if (splide.current) splide.current.splide.Components.Autoplay.play();
    };

    const stopAutoplay = () => {
        if (splide.current) splide.current.splide.Components.Autoplay.pause();
    };

    useEffect(() => {
        if (splide.current) splide.current.splide.Components.Autoplay.play();
    });
    return (
        <Splide
            hasTrack={false}
            aria-label="..."
            className={styles.carousel}
            ref={splide}
            onMouseEnter={startAutoplay}
            onMouseLeave={startAutoplay}
            // onMounted={(s) => s.Components.Autoplay.play()}
            options={{
                type: "fade",
                perPage: 1,
                perMove: 1,
                pagination: false,
                arrows: false,
                drag: false,
                speed: 3000,
                interval: 10000,
                rewind: true,
                autoplay: true,
                // breakpoints: {author
                //     },
                // }
            }}
        >
            <SplideTrack className={styles.t}>
                {data?.map((item, index) => {
                    return (
                        <SplideSlide key={index}>
                            <div className={styles.container}>
                                <div className={styles.imgContainer}>
                                    <div className={styles.shadow} />
                                    <div className={styles.img} style={{ backgroundImage: `url(${item.post_img})` }} />
                                </div>
                                <div className={styles.textWrapper}>
                                    <button type={"button"} className={styles.category} onClick={() => tagClick(item.category_name, item.category_id)}>
                                        {item.category_name}
                                    </button>
                                    <h2 className={styles.titleTop}>{item.title}</h2>
                                    <h3 className={styles.textTop} dangerouslySetInnerHTML={{ __html: item.lead }}></h3>
                                    <div className={styles.authors}>
                                        {/* {item.author.map((author, index) => {
                                            return (
                                                <Link key={index} className={styles.link} to={`/${lang}/autor/${authorUrl(author)}`}>
                                                    <div className={styles.author} style={{ backgroundImage: `url("${author.img_icon}")` }} />
                                                </Link>
                                            );
                                        })} */}
                                        {Object.values(item.author).map((author, index) => {
                                            if (author)
                                                return (
                                                    <Link key={index} className={styles.link} to={`/${lang}/autor/${authorUrl(author)}`}>
                                                        <div className={styles.author} style={{ backgroundImage: `url("${author.img_icon}")` }} />
                                                    </Link>
                                                );
                                            return null;
                                        })}
                                    </div>
                                    <Link className={styles.button} to={`/${lang}/artykul/${postUrl(item)}`}>
                                        {translation.site.btns.readMore}
                                    </Link>
                                </div>
                            </div>
                        </SplideSlide>
                    );
                    return null;
                })}
            </SplideTrack>
        </Splide>
    );
}

export default MainSplide;
