import React from "react";

export const Facebook = () => {
  return (
    <svg viewBox="0 0 64 64" width="36" height="36">
      <rect width="64" height="64" rx="0" ry="0" fill="#3b5998"></rect>
      <path
        d="M34.1,47V33.3h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6l2.8,0v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2 c-4.1,0-6.9,2.5-6.9,7V28H24v5.3h4.6V47H34.1z"
        fill="white"
      ></path>
    </svg>
  );
};

export const Twitter = () => {
  return (
    <svg viewBox="0 0 64 64" width="36" height="36">
      <rect width="64" height="64" rx="0" ry="0" fill="#00aced"></rect>
      <path
        d="M48,22.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6 C41.7,19.8,40,19,38.2,19c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5c-5.5-0.3-10.3-2.9-13.5-6.9c-0.6,1-0.9,2.1-0.9,3.3 c0,2.3,1.2,4.3,2.9,5.5c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1c2.9,1.9,6.4,2.9,10.1,2.9c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C46,24.5,47.1,23.4,48,22.1z"
        fill="white"
      ></path>
    </svg>
  );
};

export const Linkedin = () => {
  return (
    <svg viewBox="0 0 64 64" width="36" height="36">
      <rect width="64" height="64" rx="0" ry="0" fill="#007fb1"></rect>
      <path
        d="M20.4,44h5.4V26.6h-5.4V44z M23.1,18c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1 c1.7,0,3.1-1.4,3.1-3.1C26.2,19.4,24.8,18,23.1,18z M39.5,26.2c-2.6,0-4.4,1.4-5.1,2.8h-0.1v-2.4h-5.2V44h5.4v-8.6 c0-2.3,0.4-4.5,3.2-4.5c2.8,0,2.8,2.6,2.8,4.6V44H46v-9.5C46,29.8,45,26.2,39.5,26.2z"
        fill="white"
      ></path>
    </svg>
  );
};

export const ArrowLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="26.431"
      width="16.53"
      fill="none"
    >
      <path
        d="M16.53 0h-2.959L0 13.066l13.55 13.365h2.922L2.943 13.085z"
        fill="#fff"
      />
    </svg>
  );
};

export const ArrowGallery = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="8.42"
      fill="none"
    >
      <path
        d="M8.151 8.151l6.579-6.557c.359-.381.359-.966 0-1.325a.95.95 0 0 0-1.325 0L7.5 6.175 1.594.269C1.213-.09.629-.09.269.269s-.359.943 0 1.325l6.557 6.557c.381.359.966.359 1.325 0z"
        fill="#000"
      />
    </svg>
  );
};

export const EnglishLanguage = ({ fill }) => {
  return (
    <svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="35px"
      height="35px"
      viewBox="0 0 26 26"
      enableBackground="new 0 0 26 26"
    >
      <path
        fill={fill}
        d="M13,0.75c6.755,0,12.25,5.495,12.25,12.25S19.755,25.25,13,25.25S0.75,19.755,0.75,13S6.245,0.75,13,0.75
	 M13,0C5.82,0,0,5.82,0,13c0,7.18,5.82,13,13,13c7.18,0,13-5.82,13-13C26,5.82,20.18,0,13,0L13,0z"
      />
      <g>
        <g>
          <path
            fill={fill}
            d="M9.771,9.792c-1.754,0-2.979,1.314-2.979,3.196c0,1.956,1.184,3.22,3.016,3.22
			c1.533,0,2.378-0.86,2.687-1.728h-0.827c-0.344,0.707-0.969,1.066-1.859,1.066c-0.994,0-2.089-0.689-2.141-2.206l-0.006-0.155
			h4.979c0.039-1.005-0.265-1.928-0.843-2.548C11.281,10.085,10.581,9.792,9.771,9.792z M11.785,12.551H7.667l0.012-0.161
			c0.092-1.229,0.874-1.962,2.093-1.962c1.271,0,1.984,1.015,2.01,1.969L11.785,12.551z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M18.417,16.052h0.791v-3.928c0-1.569-0.646-2.332-1.974-2.332c-0.904,0-1.545,0.329-1.959,1.007
			l-0.277,0.457V9.9h-0.791v6.151h0.791V12.7c0-1.437,0.676-2.261,1.854-2.261c1.565,0,1.565,1.356,1.565,2.009V16.052z"
          />
        </g>
      </g>
    </svg>
  );
};

export const PolishLanguage = ({ fill }) => {
  return (
    <svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="35px"
      height="35px"
      viewBox="0 0 26 26"
      enableBackground="new 0 0 26 26"
    >
      <path
        fill={fill}
        d="M13,0.75c6.755,0,12.25,5.495,12.25,12.25S19.755,25.25,13,25.25S0.75,19.755,0.75,13S6.245,0.75,13,0.75
	 M13,0C5.82,0,0,5.82,0,13c0,7.18,5.82,13,13,13c7.18,0,13-5.82,13-13C26,5.82,20.18,0,13,0L13,0z"
      />
      <g>
        <g>
          <path
            fill={fill}
            d="M8.98,18.246h0.792v-3.479l0.28,0.482c0.404,0.698,1.07,1.052,1.981,1.052
			c1.256,0,2.607-1.003,2.607-3.207c0-2.205-1.353-3.208-2.607-3.208c-0.911,0-1.578,0.354-1.981,1.053l-0.28,0.483V9.996H8.98
			V18.246z M11.757,15.668c-0.938,0-2.033-0.674-2.033-2.572c0-1.899,1.095-2.573,2.033-2.573c1.231,0,1.997,0.986,1.997,2.573
			S12.988,15.668,11.757,15.668z"
          />
        </g>
        <g>
          <path fill={fill} d="M16.229,16.148h0.791V7.753h-0.791V16.148z" />
        </g>
      </g>
    </svg>
  );
};
