import React, { useEffect, useState } from "react";
import styles from "./header.module.scss";
import { Link, useLocation } from "react-router-dom";
import translation from "../../Components/Helpers/translation";
import SocialIcon from "../../Components/SocialIcons/socialIcon";
import SlideUpDown from "../../Components/Helpers/slideUpDown/slideUpDown";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { EnglishLanguage, PolishLanguage } from "../../assets/images/svg/svg";

function Header({
  white = false,
  underline = false,
  changeColor = false,
  categories,
  isClose = false,
}) {
  const location = useLocation();
  const [menuOpened, setMenuOpened] = useState(isClose);
  const { lang } = useParams();
  const [fillColor, setFillColor] = useState();

  useEffect(() => {
    if (location.pathname === "/pl" || location.pathname === "/en") {
      setFillColor("#fff");
    } else {
      setFillColor("#000");
    }
  }, [location.pathname]);

  function catBtnClicked(id) {}

  // useEffect(()=>{
  //     setMenuOpened(false)
  // },[window.location.href])

  const changeLanguage = (newLang) => {
    const currentPath = location.pathname.replace(/\/(pl|en)/, `/${newLang}`);
    window.location.href = currentPath;
  };

  setTimeout(function () {
    const checkParent = document.querySelector(`#mainListTop`);

    if (checkParent) {
      const langPl = document.querySelector(`#pl`);
      const langEn = document.querySelector(`#en`);

      if (langPl) {
        langPl.style.backgroundImage = `url('https://storage.nifc.pl/web_files/_web_assets/plWhite.png')`;
        langEn.style.backgroundImage = `url('https://storage.nifc.pl/web_files/_web_assets/enWhite.png')`;
      }
    } else {
      const langPl = document.querySelector(`#pl`);
      const langEn = document.querySelector(`#en`);

      if (langPl) {
        langPl.style.backgroundImage = `url('https://storage.nifc.pl/web_files/_web_assets/plBlack.png')`;
        langEn.style.backgroundImage = `url('https://storage.nifc.pl/web_files/_web_assets/enBlack.png')`;
      }
    }
  }, 500);
  return (
    <div className={styles.header}>
      <div className={styles.headerMain}>
        <div
          className={`${styles.headerWrapper} 
                ${location.pathname === "/" ? styles.headerWrapperMain : ""} 
                ${menuOpened ? styles.headerWrapperOpened : ""}
                ${underline ? styles.underline : ""}`}
        >
          <div className={styles.headerContainer}>
            <div className={styles.left}>
              {/* <Link to={`/`}>
                                <div className={`${white ? styles.logoWhite : styles.logo}`} />
                            </Link> */}
              <a href={`/`}>
                <div className={`${white ? styles.logoWhite : styles.logo}`} />
              </a>
              {/* <Link className={`${styles.title} ${changeColor ? styles.titleBlack : ""}`} to={`/`}>
                                {translation.site.header.title}
                            </Link> */}
              <a
                className={`${styles.title} ${
                  changeColor ? styles.titleBlack : ""
                }`}
                href={`/`}
              >
                {translation.site.header.title}
              </a>
            </div>
            <div className={styles.right}>
              <div className={styles.langs}>
                <button
                  onClick={() => changeLanguage("pl")}
                  className={`${styles.lang}`}
                >
                  <PolishLanguage fill={fillColor} />
                </button>
                <button
                  onClick={() => changeLanguage("en")}
                  className={`${styles.lang} ${styles.en}`}
                >
                  <EnglishLanguage fill={fillColor} />
                </button>
              </div>
              {/* <div className={styles.socials}>
                                <SocialIcon type={"facebook"} borders={location.pathname === "/" ? true : menuOpened} />
                                <SocialIcon type={"youtube"} borders={location.pathname === "/" ? true : menuOpened} />
                                <SocialIcon type={"twitter"} borders={location.pathname === "/" ? true : menuOpened} />
                            </div> */}
              <button
                type={"button"}
                className={`${styles.menuButton} ${
                  changeColor ? styles.menuButtonBlack : ""
                }`}
                onClick={() =>
                  setMenuOpened(menuOpened === null ? true : !menuOpened)
                }
              >
                <div
                  className={`${styles.btn} ${
                    !menuOpened ? styles.notActive : styles.active
                  }`}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.headerMenu}>
        <SlideUpDown closed={!menuOpened} closedStart={true}>
          <div
            className={styles.menuWrapper}
            // onFocus={(event) => { console.log(event, "focus"); setMenuOpened(true) }}
            // onBlur={(event) => { console.log(event, "blur"); setMenuOpened(false) }}
          >
            <div className={styles.menuContainer}>
              <div className={styles.menu}>
                <div className={styles.column}>
                  <p className={styles.title}>
                    {translation.site.header.menu.categories.title}
                  </p>
                  {categories?.map((item, index) => {
                    return (
                      <a
                        href={`/${lang}?categories=${item.id}&page=1`}
                        className={`${styles.link}`}
                        key={index}
                        onClick={() => catBtnClicked(item.id)}
                      >
                        {item.name}
                      </a>
                    );
                  })}
                  {/* <Link to={`/${lang}/autorzy`} className={`${styles.link} ${styles.bottomLink}`}>
                                        {translation.site.header.menu.categories.links[0].name}
                                    </Link> */}
                  <a
                    href={`/${lang}/autorzy`}
                    className={`${styles.link} ${styles.bottomLink}`}
                  >
                    {translation.site.header.menu.categories.links[0].name}
                  </a>
                </div>
                <div className={styles.column}>
                  <p className={styles.title}>
                    {translation.site.header.menu.ourServices.title}
                  </p>

                  <a href="https://nifc.pl" className={styles.link}>
                    {translation.site.header.menu.ourServices.links[0].name}
                  </a>

                  <a href="https://sklep.nifc.pl" className={styles.link}>
                    {translation.site.header.menu.ourServices.links[1].name}
                  </a>
                  {/* <a href={translation.site.header.menu.ourServices.links[1].href} className={styles.link}>
                                        {translation.site.header.menu.ourServices.links[1].name}
                                    </a> */}
                  {/* <a href={translation.site.header.menu.ourServices.links[2].href} className={styles.link}>
                                        {translation.site.header.menu.ourServices.links[2].name}
                                    </a> */}
                  {/* {translation.site.header.menu.ourServices.links.map((item, index) => {
                                        return (
                                            <Link to={item.href} className={styles.link} key={index}>
                                                {item.name}
                                            </Link>
                                        );
                                    })} */}
                  <div className={styles.socials}>
                    <SocialIcon
                      type={"facebook"}
                      borders={location.pathname === "/" ? true : menuOpened}
                    />
                    <SocialIcon
                      type={"youtube"}
                      borders={location.pathname === "/" ? true : menuOpened}
                    />
                    <SocialIcon
                      type={"twitter"}
                      borders={location.pathname === "/" ? true : menuOpened}
                    />
                  </div>
                </div>
                <div className={styles.column}>
                  <p className={styles.title}>
                    {translation.site.header.menu.contact.title}
                  </p>
                  <p className={styles.text1}>
                    {translation.site.header.menu.contact.name}
                  </p>
                  <p className={`${styles.text2} ${styles.marginTop}`}>
                    {translation.site.header.menu.contact.line1}
                  </p>
                  <p className={styles.text2}>
                    {translation.site.header.menu.contact.line2}
                  </p>
                  <a
                    href={`tel:${translation.site.header.menu.contact.line3.link}`}
                    className={`${styles.text2} ${styles.link2} ${styles.marginTop}`}
                  >
                    {translation.site.header.menu.contact.line3.text}
                  </a>
                  <p className={styles.text2}>
                    {translation.site.header.menu.contact.line4}
                  </p>
                  <a
                    href={`mailto:${translation.site.header.menu.contact.line5.link}`}
                    className={`${styles.text2} ${styles.link2} ${styles.marginTop}`}
                  >
                    {translation.site.header.menu.contact.line5.text}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SlideUpDown>
      </div>
    </div>
  );
}

export default Header;
